import {
  FETCH_SETTING_DATA_BEGIN,
  FETCH_SETTING_DATA_SUCCESS,
  FETCH_SETTING_DATA_FAILURE,
  UPDATE_SETTING_DATA_BEGIN,
  UPDATE_SETTING_DATA_SUCCESS,
  UPDATE_SETTING_DATA_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  items: {
    webNotification: true,
    pullTrigger: 5
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_SETTING_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SETTING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.shiphubSettings
      };
    case FETCH_SETTING_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    case UPDATE_SETTING_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        items: action.payload.shiphubSettings
      };
    case UPDATE_SETTING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.shiphubSettings
      };
    case UPDATE_SETTING_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    default:
      return state;
  }
};
