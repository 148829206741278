import {
  PRINT_CARRIER_LABEL_BEGIN,
  PRINT_CARRIER_LABEL_SUCCESS,
  PRINT_CARRIER_LABEL_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  printData: {},
  toPrintData: {
    ship_group_id: "",
    carrier_name: "",
    location_info_id: ""
  },
  success: false
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case PRINT_CARRIER_LABEL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        toPrintData: action.payload.toPrintCarrierLabelData
      };
    case PRINT_CARRIER_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        printData: action.payload.carrierLabelData
      };
    case PRINT_CARRIER_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        printData: initialState.printData
      };
    default:
      return state;
  }
};
