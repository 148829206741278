import {
    FETCH_TMALL_DELIVERY_BEGIN,
    FETCH_TMALL_DELIVERY_SUCCESS,
    FETCH_TMALL_DELIVERY_FAILURE
  } from "../actions/actionTypes";
  
  const initialState = {
    loading: false,
    error: null,
    currentPage: 0,
    items: {
      total_page_num: 0,
      noMoreData: false,
      order_list: []
    }
  };
  
  export default (state = initialState, action = null) => {
    switch (action.type) {
      case FETCH_TMALL_DELIVERY_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
          currentPage: parseInt(action.payload.page)
        };
      case FETCH_TMALL_DELIVERY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          items: action.payload.tmallData,
          currentPage:
            action.payload.tmallData.total_page_num > action.payload.page
              ? parseInt(action.payload.page)
              : parseInt(action.payload.tmallData.total_page_num)
          //to handle if total_page_num reduced after begin triggered
        };
      case FETCH_TMALL_DELIVERY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          items: initialState.items
        };
      default:
        return state;
    }
  };
  