import {
  FETCH_TO_PICK_DETAIL_BEGIN,
  FETCH_TO_PICK_DETAIL_SUCCESS,
  FETCH_TO_PICK_DETAIL_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  items: {
    ship_name: "",
    ship_mobile: "",
    ship_address: "",
    order_item_list: []
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_TO_PICK_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_TO_PICK_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.toPickDetailData
      };
    case FETCH_TO_PICK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.item
      };
    default:
      return state;
  }
};
