// App Data Actions
export const FETCH_ORDER_COUNT_BEGIN = "FETCH_ORDER_COUNT_BEGIN";
export const FETCH_ORDER_COUNT_SUCCESS = "FETCH_ORDER_COUNT_SUCCESS";
export const FETCH_ORDER_COUNT_FAILURE = "FETCH_ORDER_COUNT_FAILURE";

export const FETCH_TO_PICK_BEGIN = "FETCH_TO_PICK_BEGIN";
export const FETCH_TO_PICK_SUCCESS = "FETCH_TO_PICK_SUCCESS";
export const FETCH_TO_PICK_FAILURE = "FETCH_TO_PICK_FAILURE";

export const FETCH_TO_PICK_DETAIL_BEGIN = "FETCH_TO_PICK_DETAIL_BEGIN";
export const FETCH_TO_PICK_DETAIL_SUCCESS = "FETCH_TO_PICK_DETAIL_SUCCESS";
export const FETCH_TO_PICK_DETAIL_FAILURE = "FETCH_TO_PICK_DETAIL_FAILURE";

export const FETCH_TO_PICK_RFID_CONFIG_BEGIN = "FETCH_TO_PICK_RFID_CONFIG_BEGIN"; 
export const FETCH_TO_PICK_RFID_CONFIG_SUCCESS = "FETCH_TO_PICK_RFID_CONFIG_SUCCESS";
export const FETCH_TO_PICK_RFID_CONFIG_FAILURE = "FETCH_TO_PICK_RFID_CONFIG_FAILURE";

export const VALIDATE_TO_PICK_BEGIN = "VALIDATE_TO_PICK_BEGIN";
export const VALIDATE_TO_PICK_SUCCESS = "VALIDATE_TO_PICK_SUCCESS";
export const VALIDATE_TO_PICK_FAILURE = "VALIDATE_TO_PICK_FAILURE";

export const VALIDATE_ITEM_TO_PICK_BEGIN = "VALIDATE_ITEM_TO_PICK_BEGIN";
export const VALIDATE_ITEM_TO_PICK_SUCCESS = "VALIDATE_ITEM_TO_PICK_SUCCESS";
export const VALIDATE_ITEM_TO_PICK_FAILURE = "VALIDATE_ITEM_TO_PICK_FAILURE";

export const REJECT_TO_PICK_BEGIN = "REJECT_TO_PICK_BEGIN";
export const REJECT_TO_PICK_SUCCESS = "REJECT_TO_PICK_SUCCESS";
export const REJECT_TO_PICK_FAILURE = "REJECT_TO_PICK_FAILURE";

export const FETCH_TO_RETURN_DETAIL_BEGIN = "FETCH_TO_RETURN_DETAIL_BEGIN";
export const FETCH_TO_RETURN_DETAIL_SUCCESS = "FETCH_TO_RETURN_DETAIL_SUCCESS";
export const FETCH_TO_RETURN_DETAIL_FAILURE = "FETCH_TO_RETURN_DETAIL_FAILURE";

export const FETCH_TO_RETURN_BEGIN = "FETCH_TO_RETURN_BEGIN";
export const FETCH_TO_RETURN_SUCCESS = "FETCH_TO_RETURN_SUCCESS";
export const FETCH_TO_RETURN_FAILURE = "FETCH_TO_RETURN_FAILURE";

export const VALIDATE_TO_RETURN_BEGIN = "VALIDATE_TO_RETURN_BEGIN";
export const VALIDATE_TO_RETURN_SUCCESS = "VALIDATE_TO_RETURN_SUCCESS";
export const VALIDATE_TO_RETURN_FAILURE = "VALIDATE_TO_RETURN_FAILURE";

export const REJECT_TO_RETURN_BEGIN = "REJECT_TO_RETURN_BEGIN";
export const REJECT_TO_RETURN_SUCCESS = "REJECT_TO_RETURN_SUCCESS";
export const REJECT_TO_RETURN_FAILURE = "REJECT_TO_RETURN_FAILURE";

export const FETCH_TO_RECEIVE_BEGIN = "FETCH_TO_RECEIVE_BEGIN";
export const FETCH_TO_RECEIVE_SUCCESS = "FETCH_TO_RECEIVE_SUCCESS";
export const FETCH_TO_RECEIVE_FAILURE = "FETCH_TO_RECEIVE_FAILURE";

export const FETCH_TO_RECEIVE_DETAIL_BEGIN = "FETCH_TO_RECEIVE_DETAIL_BEGIN";
export const FETCH_TO_RECEIVE_DETAIL_SUCCESS = "FETCH_TO_RECEIVE_DETAIL_SUCCESS";
export const FETCH_TO_RECEIVE_DETAIL_FAILURE = "FETCH_TO_RECEIVE_DETAIL_FAILURE";

export const FETCH_TO_RECEIVE_SHELF_BEGIN = "FETCH_TO_RECEIVE_SHELF_BEGIN";
export const FETCH_TO_RECEIVE_SHELF_SUCCESS = "FETCH_TO_RECEIVE_SHELF_SUCCESS";
export const FETCH_TO_RECEIVE_SHELF_FAILURE = "FETCH_TO_RECEIVE_SHELF_FAILURE";

export const STORE_SHELF_BEGIN = "STORE_SHELF_BEGIN";
export const STORE_SHELF_SUCCESS = "STORE_SHELF_SUCCESS";
export const STORE_SHELF_FAILURE = "STORE_SHELF_FAILURE";

export const STORE_ABNORMAL_BEGIN = "STORE_ABNORMAL_BEGIN";
export const STORE_ABNORMAL_SUCCESS = "STORE_ABNORMAL_SUCCESS";
export const STORE_ABNORMAL_FAILURE = "STORE_ABNORMAL_FAILURE";

export const FETCH_TO_HANDOVER_BEGIN = "FETCH_TO_HANDOVER_BEGIN";
export const FETCH_TO_HANDOVER_SUCCESS = "FETCH_TO_HANDOVER_SUCCESS";
export const FETCH_TO_HANDOVER_FAILURE = "FETCH_TO_HANDOVER_FAILURE";

export const FETCH_TO_HANDOVER_DETAIL_BEGIN = "FETCH_TO_HANDOVER_DETAIL_BEGIN";
export const FETCH_TO_HANDOVER_DETAIL_SUCCESS = "FETCH_TO_HANDOVER_DETAIL_SUCCESS";
export const FETCH_TO_HANDOVER_DETAIL_FAILURE = "FETCH_TO_HANDOVER_DETAIL_FAILURE";

export const FETCH_ORDER_SEARCH_BEGIN = "FETCH_ORDER_SEARCH_BEGIN";
export const FETCH_ORDER_SEARCH_SUCCESS = "FETCH_ORDER_SEARCH_SUCCESS";
export const FETCH_ORDER_SEARCH_FAILURE = "FETCH_ORDER_SEARCH_FAILURE";

export const FETCH_ORDER_SEARCH_DETAIL_BEGIN = "FETCH_ORDER_SEARCH_DETAIL_BEGIN";
export const FETCH_ORDER_SEARCH_DETAIL_SUCCESS = "FETCH_ORDER_SEARCH_DETAIL_SUCCESS";
export const FETCH_ORDER_SEARCH_DETAIL_FAILURE = "FETCH_ORDER_SEARCH_DETAIL_FAILURE";

export const FETCH_TO_SHIP_BEGIN = "FETCH_TO_SHIP_BEGIN";
export const FETCH_TO_SHIP_SUCCESS = "FETCH_TO_SHIP_SUCCESS";
export const FETCH_TO_SHIP_FAILURE = "FETCH_TO_SHIP_FAILURE";

export const FETCH_TO_SHIP_DETAIL_BEGIN = "FETCH_TO_SHIP_DETAIL_BEGIN";
export const FETCH_TO_SHIP_DETAIL_SUCCESS = "FETCH_TO_SHIP_DETAIL_SUCCESS";
export const FETCH_TO_SHIP_DETAIL_FAILURE = "FETCH_TO_SHIP_DETAIL_FAILURE";

export const FETCH_CANCELLED_ORDER_BEGIN = "FETCH_CANCELLED_ORDER_BEGIN";
export const FETCH_CANCELLED_ORDER_SUCCESS = "FETCH_CANCELLED_ORDER_SUCCESS";
export const FETCH_CANCELLED_ORDER_FAILURE = "FETCH_CANCELLED_ORDER_FAILURE";

export const FETCH_SHIPPED_ORDER_BEGIN = "FETCH_SHIPPED_ORDER_BEGIN";
export const FETCH_SHIPPED_ORDER_SUCCESS = "FETCH_SHIPPED_ORDER_SUCCESS";
export const FETCH_SHIPPED_ORDER_FAILURE = "FETCH_SHIPPED_ORDER_FAILURE";

export const FETCH_TRACKING_DETAIL_BEGIN = "FETCH_TRACKING_DETAIL_BEGIN";
export const FETCH_TRACKING_DETAIL_SUCCESS = "FETCH_TRACKING_DETAIL_SUCCESS";
export const FETCH_TRACKING_DETAIL_FAILURE = "FETCH_TRACKING_DETAIL_FAILURE";

export const FETCH_SETTING_DATA_BEGIN = "FETCH_SETTING_DATA_BEGIN";
export const FETCH_SETTING_DATA_SUCCESS = "FETCH_SETTING_DATA_SUCCESS";
export const FETCH_SETTING_DATA_FAILURE = "FETCH_SETTING_DATA_FAILURE";

export const UPDATE_SETTING_DATA_BEGIN = "UPDATE_SETTING_DATA_BEGIN";
export const UPDATE_SETTING_DATA_SUCCESS = "UPDATE_SETTING_DATA_SUCCESS";
export const UPDATE_SETTING_DATA_FAILURE = "UPDATE_SETTING_DATA_FAILURE";

export const FETCH_USER_INFO_BEGIN = "FETCH_USER_INFO_BEGIN";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

export const FETCH_LOGOUT_BEGIN = "FETCH_LOGOUT_BEGIN";
export const FETCH_LOGOUT_SUCCESS = "FETCH_LOGOUT_SUCCESS";
export const FETCH_LOGOUT_FAILURE = "FETCH_LOGOUT_FAILURE";

export const TRY_LOGIN_BEGIN = "TRY_LOGIN_BEGIN";
export const TRY_LOGIN_SUCCESS = "TRY_LOGIN_SUCCESS";
export const TRY_LOGIN_FAILURE = "TRY_LOGIN_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

export const PRINT_CARRIER_LABEL_BEGIN = "PRINT_CARRIER_LABEL_BEGIN";
export const PRINT_CARRIER_LABEL_SUCCESS = "PRINT_CARRIER_LABEL_SUCCESS";
export const PRINT_CARRIER_LABEL_FAILURE = "PRINT_CARRIER_LABEL_FAILURE";

export const PRINT_SHOPPING_LIST_BEGIN = "PRINT_SHOPPING_LIST_BEGIN";
export const PRINT_SHOPPING_LIST_SUCCESS = "PRINT_SHOPPING_LIST_SUCCESS";
export const PRINT_SHOPPING_LIST_FAILURE = "PRINT_SHOPPING_LIST_FAILURE";

//Tmall
export const FETCH_TMALL_DELIVERY_BEGIN = "FETCH_TMALL_DELIVERY_BEGIN";
export const FETCH_TMALL_DELIVERY_SUCCESS = "FETCH_TMALL_DELIVERY_SUCCESS";
export const FETCH_TMALL_DELIVERY_FAILURE = "FETCH_TMALL_DELIVERY_FAILURE";

export const FETCH_TMALL_DELIVERY_DETAIL_BEGIN = "FETCH_TMALL_DELIVERY_DETAIL_BEGIN";
export const FETCH_TMALL_DELIVERY_DETAIL_SUCCESS = "FETCH_TMALL_DELIVERY_DETAIL_SUCCESS";
export const FETCH_TMALL_DELIVERY_DETAIL_FAILURE = "FETCH_TMALL_DELIVERY_DETAIL_FAILURE";

//NPS
export const NPS_CHECK_ENABLED_BEGIN = "NPS_CHECK_ENABLED_BEGIN";
export const NPS_CHECK_ENABLED_SUCCESS = "NPS_CHECK_ENABLED_SUCCESS";
export const NPS_CHECK_ENABLED_FAILURE = "NPS_CHECK_ENABLED_FAILURE";

export const NPS_VOTE_BEGIN = "NPS_VOTE_BEGIN";
export const NPS_VOTE_SUCCESS = "NPS_VOTE_SUCCESS";
export const NPS_VOTE_FAILURE = "NPS_VOTE_FAILURE";
