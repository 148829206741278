import {
  FETCH_TO_HANDOVER_BEGIN,
  FETCH_TO_HANDOVER_SUCCESS,
  FETCH_TO_HANDOVER_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  currentPage: 0,
  items: {
    total_page_num: 0,
    noMoreData: false,
    order_list: []
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_TO_HANDOVER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        currentPage: parseInt(action.payload.page)
      };
    case FETCH_TO_HANDOVER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.toReceiveData,
        currentPage:
          action.payload.page > action.payload.toReceiveData.total_page_num
            ? parseInt(action.payload.toReceiveData.total_page_num)
            : parseInt(action.payload.page)
        //to handle if total_page_num reduced after begin triggered
      };
    case FETCH_TO_HANDOVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    default:
      return state;
  }
};
