import {
  VALIDATE_ITEM_TO_PICK_BEGIN,
  VALIDATE_ITEM_TO_PICK_SUCCESS,
  VALIDATE_ITEM_TO_PICK_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  items: {
    order_item_list: [
    ]
  },
  success: false
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case VALIDATE_ITEM_TO_PICK_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        items: action.payload.toValidateData,
        success: false
      };
    case VALIDATE_ITEM_TO_PICK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        items: action.payload.validateResult,
      };
    case VALIDATE_ITEM_TO_PICK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    default:
      return state;
  }
};
