import {
  FETCH_USER_INFO_BEGIN,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  TOKEN_EXPIRED,
  NPS_CHECK_ENABLED_SUCCESS,
  NPS_VOTE_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  userInfo: {
    displayName: "",
    partyNumber: "",
    uid: ""
  },
  isAuthenticated: false,
  npsToday: false,
  doneNps: false
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_USER_INFO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_USER_INFO_SUCCESS:
      let info = {
        displayName: action.payload.userInfo.displayName,
        partyNumber: action.payload.userInfo.partyNumber ? action.payload.userInfo.partyNumber : "",
        uid: action.payload.userInfo.uid
      }
      return {
        ...state,
        loading: false,
        error: (info && typeof(info.partyNumber) == "string") ? null : { messages: "Party Number is not allowed, Contact IT" },
        userInfo: info,
        isAuthenticated: (info && typeof(info.partyNumber) == "string") ? true : false
      };
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        userInfo: initialState.userInfo,
        isAuthenticated: false
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        isAuthenticated: false
      }
    case NPS_CHECK_ENABLED_SUCCESS:
      return {
        ...state,
        npsToday: action.payload.response.data
        // npsToday: true
      }
    case NPS_VOTE_SUCCESS:
      return {
        ...state,
        doneNps: true
      }
    default:
      return state;
  }
};
