import {
  VALIDATE_TO_PICK_BEGIN,
  VALIDATE_TO_PICK_SUCCESS,
  VALIDATE_TO_PICK_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  items: {
    action_time: "",
    client_name: "",
    ship_action_name: "",
    ship_group_id: "",
    sign: "",
    user_name: ""
  },
  success: false
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case VALIDATE_TO_PICK_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        items: action.payload.toValidateData,
        success: false
      };
    case VALIDATE_TO_PICK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true
      };
    case VALIDATE_TO_PICK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    default:
      return state;
  }
};
