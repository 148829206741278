import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import indexRoutes from "routes/index.jsx";
import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import store from "./stores/reducers/stores";
import asyncComponent from "services/AsyncComponent.jsx";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { blue } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core";
const Authorization = asyncComponent(() => import("./views/Components/Authentication/Authorization"));
const UnAuthorization = asyncComponent(() => import("./views/Components/Authentication/UnAuthorization"));

export const TOKEN_NAME = "AUTH_TOKEN";
const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    useNextVariants: true,
  }
});
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Authorization
              notAuthenticatedView={ UnAuthorization }
              routes={indexRoutes}
              fedid={{
                clientId: process.env.REACT_APP_FEDID_CLIENT_ID,
                redirectUrl: window.location.origin,
                serviceUrl: process.env.REACT_APP_FEDID_URL || ""
              }}
              tokenName={TOKEN_NAME}
            />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>,
  </MuiThemeProvider>,
  document.getElementById("root")
);
