import {
  REJECT_TO_RETURN_BEGIN,
  REJECT_TO_RETURN_SUCCESS,
  REJECT_TO_RETURN_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: {
    action_time: "",
    client_name: "",
    ship_action_name: "",
    ship_group_id: "",
    sign: "",
    user_name: "",
    cancellation_reason: "",
    cancellation_remark: ""
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case REJECT_TO_RETURN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        items: action.payload.toRejectData,
        success: false
      };
    case REJECT_TO_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true
      };
    case REJECT_TO_RETURN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: initialState.items
      };
    default:
      return state;
  }
};
