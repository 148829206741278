import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import dashboardReducer from "./dashboardReducer";
import toPickReducer from "./toPickReducer";
import toPickDetailReducer from "./toPickDetailReducer";
import toPickRfidConfigReducer from "./toPickRfidConfigReducer";
import toReturnReducer from "./toReturnReducer";
import toReturnDetailReducer from "./toReturnDetailReducer";
import toReceiveReducer from "./toReceiveReducer";
import toReceiveDetailReducer from "./toReceiveDetailReducer";
import toHandoverReducer from "./toHandoverReducer";
import toHandoverDetailReducer from "./toHandoverDetailReducer";
import orderSearchReducer from "./orderSearchReducer";
import orderSearchDetailReducer from "./orderSearchDetailReducer";
import toShipReducer from "./toShipReducer";
import toShipDetailReducer from "./toShipDetailReducer";
import cancelledOrderReducer from "./cancelledOrderReducer";
import shippedOrderReducer from "./shippedOrderReducer";
import shippedOrderTrackingDetailReducer from "./shippedOrderTrackingDetailReducer";
import settingReducer from "./settingReducer";
import userInfoReducer from "./userInfoReducer";
import validateToPickReducer from "./validateToPickReducer";
import validateItemToPickReducer from "./validateItemToPickReducer";
import rejectToPickReducer from "./rejectToPickReducer";
import rejectToReturnReducer from "./rejectToReturnReducer";
import logoutReducer from "./logoutReducer";
import printCarrierLabelReducer from "./printCarrierLabelReducer";
import tryLoginReducer from "./tryLoginReducer";
import printShoppingListReducer from "./printShoppingListReducer";
import tmallDeliveryReducer from "./tmallDeliveryReducer";
import tmallDeliveryDetailReducer from "./tmallDeliveryDetailReducer";

let rootReducers = combineReducers({
  dashboardReducer: dashboardReducer,
  toPickReducer: toPickReducer,
  toPickDetailReducer: toPickDetailReducer,
  toPickRfidConfigReducer: toPickRfidConfigReducer,
  toReturnReducer: toReturnReducer,
  toReturnDetailReducer: toReturnDetailReducer,
  toReceiveReducer: toReceiveReducer,
  toReceiveDetailReducer: toReceiveDetailReducer,
  toHandoverReducer: toHandoverReducer,
  toHandoverDetailReducer: toHandoverDetailReducer,
  orderSearchReducer: orderSearchReducer,
  orderSearchDetailReducer: orderSearchDetailReducer,
  toShipReducer: toShipReducer,
  toShipDetailReducer: toShipDetailReducer,
  cancelledOrderReducer: cancelledOrderReducer,
  shippedOrderReducer: shippedOrderReducer,
  shippedOrderTrackingDetailReducer: shippedOrderTrackingDetailReducer,
  settingReducer: settingReducer,
  userInfoReducer: userInfoReducer,
  validateToPickReducer: validateToPickReducer,
  validateItemToPickReducer: validateItemToPickReducer,
  rejectToPickReducer: rejectToPickReducer,
  rejectToReturnReducer: rejectToReturnReducer,
  logoutReducer: logoutReducer,
  printCarrierLabelReducer: printCarrierLabelReducer,
  tryLoginReducer: tryLoginReducer,
  printShoppingListReducer: printShoppingListReducer,
  tmallDeliveryReducer: tmallDeliveryReducer,
  tmallDeliveryDetailReducer: tmallDeliveryDetailReducer
});

let store;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  store = createStore(rootReducers, applyMiddleware(thunk, logger));
} else {
  store = createStore(rootReducers, applyMiddleware(thunk));
}

export default store;
