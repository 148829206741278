import {
  FETCH_ORDER_COUNT_BEGIN,
  FETCH_ORDER_COUNT_SUCCESS,
  FETCH_ORDER_COUNT_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  item: {
    to_pick_count: 0,
    to_return_count: 0,
    to_ship_count: 0,
    delivery_abnormal_count: 0
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_ORDER_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: action.payload.ordersCount
      };
    case FETCH_ORDER_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        item: initialState.item
      };
    default:
      return state;
  }
};
