import {
  FETCH_ORDER_SEARCH_DETAIL_BEGIN,
  FETCH_ORDER_SEARCH_DETAIL_SUCCESS,
  FETCH_ORDER_SEARCH_DETAIL_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  detail: {
    delivery_information: {},
    order_information: [],
    order_status_records: [],
    ship_group_information: {}
  }
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_ORDER_SEARCH_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORDER_SEARCH_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        detail: action.payload.orderSearchDetailData
      };
    case FETCH_ORDER_SEARCH_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        detail: initialState.detail
      };
    default:
      return state;
  }
};
