import {
  TRY_LOGIN_BEGIN,
  TRY_LOGIN_SUCCESS,
  TRY_LOGIN_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: {}
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case TRY_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        success: false
      };
    case TRY_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        items: action.payload.loginData
      };
    case TRY_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false
      };
    default:
      return state;
  }
};
