import {
  FETCH_LOGOUT_BEGIN,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_FAILURE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  status: null
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.payload.status
      };
    case FETCH_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: initialState.status
      };
    default:
      return state;
  }
};
